import { PhoneNumberType } from 'google-libphonenumber'
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export default {
    data() {
        return {}
    },

    computed: {
        customerItems() {
            return [
                {
                    label: 'Name',
                    value: `${this.customer.first_name || '–'}
                        <br />
                        ${this.customer.last_name || '–'}`
                },
                {
                    label: 'Adresse',
                    value: `${this.customer.address_street || '–'}
                        <br />
                        ${this.customer.address_zip || '–'} 
                        ${this.customer.address_city || '–'}`
                }
            ]
        },

        authorizedItems() {
            return [
                {
                    label: 'Name',
                    value: `${this.authorizedRepresentative.first_name || '–'}
                        <br />
                        ${this.authorizedRepresentative.last_name || '–'}`
                },
                {
                    label: 'Adresse',
                    value: `${this.authorizedRepresentative.address_street ||
                        '–'}
                        <br />
                        ${this.authorizedRepresentative.address_zip || '–'} 
                        ${this.authorizedRepresentative.address_city || '–'}`
                }
            ]
        },

        hasAuthorizedRepresentative() {
            return (
                !!this.authorizedRepresentative.first_name &&
                !!this.authorizedRepresentative.last_name
            )
        },

        hasAlternativePerson() {
            return this.form.authority && this.form.authority.alternativePerson
        },

        driverItems() {
            return [
                {
                    label: 'Name',
                    value: `${this.driver.firstName || '–'}
                        <br />
                        ${this.driver.lastName || '–'}`
                },
                {
                    label: 'Firma',
                    value: this.driver && this.driver.company
                },
                {
                    label: 'MA-Nummer',
                    value: this.driver && this.driver.employeeNumber
                }
            ]
        },

        alternativePersonItems() {
            return [
                {
                    label: 'Name',
                    value: `${(this.form.authority &&
                        this.form.authority.alternativePersonFirstName) ||
                        '–'}
                        <br />
                        ${(this.form.authority &&
                            this.form.authority.alternativePersonLastName) ||
                            '–'}`
                },
                {
                    label: 'Adresse',
                    value: `${this.form.authority && this.form.authority.alternativePersonAddressStreet || '–'}
                        <br />
                        ${this.form.authority && this.form.authority.alternativePersonAddressZip || '–'} 
                        ${this.form.authority && this.form.authority.alternativePersonAddressCity || '–'}`
                }
            ]
        },

        customerOrAuthorizedItems() {
            let customerItems = this.customerItems
            if (
                this.hasAuthorizedRepresentative &&
                !this.contractPartnerOnSite
            ) {
                customerItems = this.authorizedItems
            }

            if (this.hasAlternativePerson) {
                customerItems = this.alternativePersonItems
            }

            return customerItems
        },

        customer() {
            return (this.form.vehicle && this.form.vehicle.customer) || {}
        },

        driver() {
            return (this.form && this.form.driver) || {}
        },

        authorizedRepresentative() {
            return (
                (this.form.vehicle &&
                    this.form.vehicle.authorizedRepresentative) ||
                {}
            )
        },

        contractPartnerOnSite() {
            return (
                (this.form &&
                    this.form.authority &&
                    this.form.authority.contractPartnerOnSite) ||
                false
            )
        },

        hasValidCustomerPhonenumber() {
            if (!this.customer.phone_number || (this.customer.phone_number === '')) {
                return false;
            }
            try {
                const phoneNumber = phoneUtil.parseAndKeepRawInput(this.customer.phone_number, 'DE');
                return (phoneUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE)
            } catch (ex) {
                return false;
            }
        },

        emptyCustomerPhonenumber() {
            return (!this.customer.phone_number || (this.customer.phone_number === ''))
        },

        hasValidAuthorizedPhonenumber() {
            if (!this.authorizedRepresentative.phone_number || (this.authorizedRepresentative.phone_number === '')) {
                return false;
            }
            try {
                const phoneNumber = phoneUtil.parseAndKeepRawInput(this.authorizedRepresentative.phone_number, 'DE');
                return (phoneUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE)
            } catch (ex) {
                return false;
            }
        }
    }
}
